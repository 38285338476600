@import 'styles/partials/_variables.module.scss';

.card {
  border: solid 1px $gray300;
  border-radius: 8px;
  box-shadow: 0 4px 24px 0 $gray-transparent;

  &Content {
    align-items: center;
    background-color: $white;
    display: flex;
    height: 122px;
    padding: 0;
    width: 100%;
    &:last-child {
      padding-bottom: 0;
    }

    & .description {
      color: $primary700;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 8px;
    }

    & .image {
      margin-left: 32px;
    }

    & .message {
      color: $gray800;
    }
  }
}
