@import 'styles/partials/variables.module';
@import 'styles/partials/breakpoints';

.myTeamMenu {
  & ul {
    padding: 0;
  }
  & li {
    padding: 0;
    &:not(:last-child) {
      border-bottom: 1px solid $gray400;
    }
  }
  .marginSession {
    margin-top: 4px;
  }
  .paper {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 $transparent-gray;
  }
  .sessionMenu {
    & span {
      font-size: 1rem;
      font-weight: 600;
    }
    .changeRole {
      background-color: $white;
      color: $gray800;
      justify-content: flex-start;
      padding: 8px 16px;
      &:hover {
        background-color: $primary200;
        color: $primary700;
      }
    }
  }
}
