@use "src/styles/partials/variables.module" as variables;

.renderTag {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 90%;

  p,
  svg,
  span {
    flex-grow: 0;
    margin: auto;
  }

  span {
    margin-right: 4px;
  }
}

.free {
  color: variables.$green;
}

.busy {
  color: variables.$yellow;
}

.unavailable {
  color: variables.$red100;
}

.error {
  border: 1px variables.$gray600 solid;
  border-radius: 100%;
  color: variables.$white;
  height: 17px;
  width: 19px;
}
