@import 'styles/partials/_variables.module.scss';

.appraising,
.approved,
.draft,
.rejected,
.pending {
  border-radius: 50%;
  font-size: 0.625rem;
  font-weight: bold;
  height: 16px;
  line-height: 16px;
  text-align: center;
  width: 16px;
}

.appraising {
  background-color: $appraising;
  color: $appraising-text;
}

.approved {
  background-color: $approved;
  color: $approved-text;
}

.draft {
  background-color: white;
  border: 1px solid $gray800;
  color: $gray900;
}

.pending {
  background-color: $on-hold;
  color: $on-hold-text;
}

.rejected {
  background-color: rgba($red100, 0.15);
  color: $red200;
}
