@import 'styles/partials/variables.module.scss';

.teamRosterRowCell {
  color: $black;
  cursor: default;
  max-width: 0;
  overflow: hidden;
  padding: 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  &BillRate{
    padding: 8px 16px;
  }
}

.rightAlign {
  text-align: end;
}