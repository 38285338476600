@import 'styles/partials/variables.module.scss';

.tableContainer {
  box-shadow: none;
  font-size: 18px;
  width: 100%;

  & tbody[class*='MuiTableBody-root'] {
    font-size: 14px;
    font-weight: 400;
    height: 24px;
    line-height: 24px;

    &:nth-child(odd) {
      background-color: $gray200;
    }

    &:hover {
      background-color: $primary200;
    }
  }

  & .headerContainer {
    border-bottom: 1px solid $gray400;

    & .headerCell {
      border-bottom: none;
      color: $gray800;
      font-size: 18px;
      font-weight: bold;
      padding: 12px 16px;
      text-align: left;
    }

    & .expandedMenu {
      color: $primary;

      & svg {
        fill: $primary;
      }
    }

    & .center {
      text-align: center;
    }

    & .left {
      text-align: left;

      &:first-child {
        padding-left: 16px;
      }
    }

    & .headerCellContent {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: space-between;

      &:hover {
        color: $primary;

        & svg {
          fill: $primary;
        }
      }
    }
  }

  & .section {
    background-color: $primary100;
    color: $primary700;
    height: 24px;
    padding: 0;
  }

  &::-webkit-scrollbar {
    width: 13px;
    &-button {
      display: none;
    }
    &-thumb {
      background-color: $gray700;
      border-bottom: 4px $white solid;
      border-radius: 6px;
      border-top: 4px $white solid;
    }
    &-track {
      margin: 8px 0;
    }
  }
}
