@import 'styles/partials/_variables.module.scss';

.projectDetailsOverview {

  &Content {
    align-items: flex-start;
    background-color: $white;
    display: flex;
    flex-direction: column;
    padding: 24px 32px 34px;
    width: 100%;

    &Title {
      font-family: $font-roboto;
      font-size: 1.25rem;
      padding-bottom: 12px;
    }

    &Costs {
      display: flex;

      &Section {
        display: flex;
        flex-direction: column;
        width: 270px;

        &Title {
          color: $primary700;
          font-size: 0.875rem;
          margin-bottom: 4px;
        }

        &Total {
          color: $primary700;
          font-family: $font-roboto;
          font-size: 1.5rem;
          font-weight: bold;
        }

        &Separator {
          border-right: 1px solid $primary700;
          margin: 0 24px;
          width: 1px;
        }
      }
    }
  }
}