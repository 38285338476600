@import 'styles/partials/variables.module.scss';

.paginationHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  &ShowingCount {
    line-height: 24px;
  }

  &RowsForm {
    align-items: center;
    display: flex;
    justify-content: right;

    & .textPerPage {
      font-size: 0.875rem;
      line-height: 24px;
      margin-right: 8px;
    }

    & .selectContainer {
      &__input {
        font-size: 0.875rem;
        line-height: 24px;

        &:focus {
          background: $white;
        }
      }
      &__select {
        height: 24px;
      }
    }

    &:hover {
      color: $primary;
      & .selectContainer {
        &__input,
        &__select,
        &__filterIcon {
          color: $primary;
        }
      }
    }
  }
}

.menu {
  &Paper {
    margin-top: 38px;
    width: 154px;
  }
  &Item{
    line-height: 24px;
    padding: 8px 16px;
  }
}
