.button {
  font-size: 1.125rem;
  height: 40px;
  padding: 16px;
  &Cancel {
    margin-right: 24px;
  }
}

.formContainer {
  display: flex;
  flex-direction: column;
  height: 50%;
  margin: 56px;
  padding: 0 40px;
}

.formField {
  margin: 18px 0;
}

.modalContainer {
  width: 760px;
}

.title {
  margin-bottom: 24px;
}
