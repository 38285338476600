@import 'styles/partials/variables.module';

.emptyState {
  background-color: $gray100;
  border-radius: 8px;
  padding: 30px 0 26px;
}

.failedStateTextSub {
  color: $gray600;
  margin: auto;
  text-align: center;
  width: 40%;
}

.failedStateText {
  color: $gray600;
  text-align: center;
}
