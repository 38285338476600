@import 'styles/partials/variables.module.scss';

.dialog > div > [class*='MuiPaper-root'] {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-width: 325px;
  min-height: 220px;
  padding: 32px;
  width: 325px;

  & .dialogTitle {
    font-family: Roboto, serif;
    font-size: 1.25rem;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 16px;
  }

  & .dialogMessage {
    color: $gray800;
    font-size: 1rem;
    margin-bottom: 40px;
    max-width: 250px;
    width: 250px;
  }

  & .dialogActions {
    display: flex;
    justify-content: right;
    width: 100%;

    & .dialogButton {
      margin-left: 24px;
      padding: 8px 16px;

      &.cautionButton {
        background-color: $red200;
        color: $white;
        padding: 8px 16px;
      }
    }
  }
}
