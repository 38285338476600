@import 'styles/partials/variables.module.scss';

.projectDetailsContainer {
  .mainFormTitle {
    margin-bottom: 12px;
  }
  .formTitle {
    margin: 44px 0 12px;
  }
  .formField {
    margin: 12px 0;
    &CalendarSpacing {
      width: 24px;
    }
    .subtitle {
      color: $gray800;
      font-size: 0.75rem;
      margin-bottom: 4px;
    }
    .currentValue {
      font-size: 1rem;
    }
  }
}

.skeletonContainer {
  width: 664px;
}
