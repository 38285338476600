@import 'styles/partials/_variables.module.scss';

.borderShadow {
  border: 1px solid $gray100;
  border-radius: 8px;
  box-shadow: 0 4px 4px $transparent-gray;
}

.popper {
  margin-top: 4px;
  position: absolute;
  &DisablePortal {
    position: relative;
    width: 100%;
  }
  .paper {
    box-shadow: none;
    font-size: 13px;
    margin: 0;
  }
}

.iconArrow {
  color: $gray800;
  right: 16px;
  &Open {
    transform: rotate(0);
  }
}

.input {
  height: 39px;
  z-index: 1500;
}

.inputBase {
  border-bottom: 1px solid $gray400;
  padding: 8px 16px;
  width: 100%;
  & input {
    &:focus {
      opacity: 1;
    }
    &::placeholder {
      color: $gray800;
      font-size: 1rem;
    }
  }

  .iconSearch {
    color: $gray600;
  }
}

.option {
  align-items: flex-start;
  min-height: auto;
  overflow: hidden;
  padding: 8px 0 8px 16px;
  white-space: nowrap;
  &:focus {
    background-color: $white;
  }
  &:hover {
    background-color: $primary200;
    & p {
      color: $primary700;
    }
  }
  &Selected {
    background-color: $white !important;
    &:hover {
      background-color: $primary200 !important;
      & p {
        color: $primary700;
      }
    }
  }
}

.textOption {
  color: $gray800;
  font-size: 1rem;
  width: 100%;
}

.noOption {
  display: flex;
  width: 100%;
}

.listbox {
  max-height: 195px;
  padding: 0;
  & li {
    height: 39px;
    &:not(:last-child) {
      border-bottom: 1px solid $gray400;
    }
    &:hover {
      background-color: $primary200;
      & p {
        color: $primary700;
      }
    }
  }
}

.select {
  &:focus {
    background-color: transparent;
  }
}

.selectInputBaseRoot {
  padding-right: 0;
}

.selectInputContainer {
  width: 100%;
  .outlinedBorder {
    border: 1px solid $gray600;
    & span {
      padding-right: 10px;
    }
  }
  .label {
    top: -2px;
  }

  .iconArrowContainer {
    cursor: pointer;
    height: 100%;
    margin: 0;
    padding: 0 16px 0 0;
  }

  .textField {
    cursor: pointer;
    &::placeholder {
      color: $black;
      opacity: 1;
    }

    &Disabled {
      cursor: default;
    }
  }
}

.scrollbar::-webkit-scrollbar {
  width: 12px;
  &-button {
    display: none;
  }
  &-thumb {
    background-color: $gray700;
    border-left: 4px $white solid;
    border-radius: 5px;
    border-right: 4px $white solid;
  }
  &-track {
    margin: 8px 0;
  }
}
