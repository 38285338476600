@import 'styles/partials/_variables.module.scss';

.horizontalFiller,
.verticalFiller {
  left: 0;
  position: relative;
  right: 0;
}

.horizontalFiller {
  border-bottom: 1.5px dashed $primary700;
  flex: 1;
  height: 50%;
  max-width: 600px;
}

.verticalFiller {
  border-right: 1px dashed $black;
  margin: 2px 0;
  text-align: center;
  width: 8px;
}
