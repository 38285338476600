@import 'styles/partials/_variables.module.scss';

.expandableCell {
  .expandableCellContent {
    align-items: center;
    display: flex;

    & button[class*='MuiIconButton-root'] {
      background-color: rgba($red100, .15);
      border-radius: 8px;
      color: $red200;
      margin-right: 8px;
      padding: 4px;
    }

    & span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    & svg {
      cursor: pointer;
    }
  }
}