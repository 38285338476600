$approved: #e9f8ef;
$approved-text: #1a703e;
$black: #000000;
$color-link: #27798b;
$font-nunito: 'Nunito Sans', sans-serif;
$font-roboto: 'Roboto', sans-serif;
$gray100: #fcfcfc;
$gray200: #f7f7f7;
$gray300: #f8f8fa;
$gray400: #c4c4c4;
$gray500: #bdbdbd;
$gray600: #a3a3a3;
$gray700: #848484;
$gray800: #4f4f4f;
$gray900: #232f34;
$gray-transparent: rgba(26, 29, 31, 0.04);
$gray-2transparent: rgba(0, 0, 0, 0.2);
$gray-5transparent: rgba(163, 163, 163, 0.05);
$green: #6fcf97;
$primary: #4ba9c4;
$primary100: #f8fbfd;
$primary200: #edf6f9;
$primary300: #ecfbff;
$primary400: #81c3d6;
$primary600: #078bd4;
$primary700: #325d7f;
$on-hold: #fff6d9;
$on-hold-text: #b18802;
$appraising: #ffead6;
$appraising-text: #b34a00;
$orange: #fd8661;
$orange100: #ffefef;
$red100: #fd7d7e;
$red200: #fe5354;
$red-error: rgba(253, 97, 98, 0.1);
$red-field-error: #c60101;
$rejected: #ffecec;
$transparent-blue: rgba(43, 100, 116, 0.22);
$transparent-dark: rgba(0, 0, 0, 0.6);
$transparent-gray: rgba(0, 0, 0, 0.16);
$ui-transparent-blue: rgba(75, 169, 196, 0.8);
$yellow: #ffba08;
$white: #ffffff;
// TODO RENAME ONCE DESIGN SYSTEM IS UPDATED
$gray-disabled-button: #f2f3f4;
$gray-skeleton: #f0f0f0;
$gray-tab-border: #d9d9d9;

:export {
  appraising: $appraising;
  appraisingText: $appraising-text;
  approvedText: $approved-text;
  black: $black;
  colorLink: $color-link;
  fontNunito: $font-nunito;
  fontRoboto: $font-roboto;
  gray100: $gray100;
  gray200: $gray200;
  gray2Transparent: $gray-2transparent;
  gray300: $gray300;
  gray400: $gray400;
  gray500: $gray500;
  gray5Transparent: $gray-5transparent;
  gray600: $gray600;
  gray700: $gray700;
  gray800: $gray800;
  gray900: $gray900;
  grayDisabledButton: $gray-disabled-button;
  graySkeleton: $gray-skeleton;
  grayTabBorder: $gray-tab-border;
  grayTransparent: $gray-transparent;
  grenn: $green;
  onHold: $on-hold;
  onHoldText: $on-hold-text;
  orange: $orange;
  orange100: $orange100;
  primary: $primary;
  primary100: $primary100;
  primary200: $primary200;
  primary300: $primary300;
  primary400: $primary400;
  primary600: $primary600;
  primary700: $primary700;
  red100: $red100;
  red200: $red200;
  redError: $red-error;
  redFieldError: $red-field-error;
  rejected: $rejected;
  transparentBlue: $transparent-blue;
  transparentDark: $transparent-dark;
  transparentGray: $transparent-gray;
  uiTransparentBlue: $ui-transparent-blue;
  white: $white;
}
