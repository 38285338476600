@import 'styles/partials/_variables.module.scss';

.helperContainer {
  align-content: center;
  background-color: $primary200;
  display: flex;
  flex-direction: row;
  height: 41px;
  justify-content: center;
  margin-bottom: -43px;
  position: relative;
  width: 100%;
  &__description {
    align-items: center;
    color: $primary700;
    display: inline-flex;
    font-weight: bold;
    text-align: center;
  }
  &__icon {
    color: $primary700;
    height: 41px;
    margin-right: 12px;
    position: absolute;
    right: 0;
    width: 41px;
  }
}
