@import 'styles/partials/variables.module.scss';

.container {
  background-color: $primary100;
  min-height: 100%;

  & .breadCrumbs {
    margin: 16px 24px 0;
  }

  & .generalContainer {
    flex-direction: column;
  }

  &Card {
    margin-top: 32px;
    padding: 0 122px;
  }

  & .stepContainer {
    margin-top: 32px;
    padding: 0 122px 64px;
    width: 100%;

    & .cardContent {
      padding: 0;
    }

    & .cardActions {
      justify-content: flex-end;
      margin: 12px 98px 56px auto;
      padding: 0;
      &StepTwo {
        margin-top: 8px;
      }
      & .button {
        height: 40px;
        margin-left: 24px;
      }
    }
  }

  & .stepperChild {
    padding: 56px 98px;
  }

  & .tabPanelChild {
    padding: 56px 98px;
  }
}

.forecastOverwrite {
  & .row {
    margin-bottom: 1rem;
  }

  & .forecastContainer {
    margin-bottom: 1rem;
    width: 100%;
  }

  & .value {
    margin-bottom: 0.5rem;
  }

  & .tooltip {
    height: 18px;
    width: 18px;
  }

  & .tooltipContainer {
    align-items: center;
    display: flex;
    margin-left: 1rem;
  }
}

.skeletonContainer {
  max-width: 45vw;
  width: 664px;
}
