@import 'styles/partials/variables.module.scss';

.currency {
  background-color: $primary;
  border-radius: 0 8px 8px 0;
  color: $white;
  font-size: .8125rem;
  padding: 15px 8px 15px 16px;
  width: 34px;
  &:focus {
    background-color: $primary;
    border-radius: 0 8px 8px 0;
  }
  &:hover {
    background-color: $primary;
  }
}

.dropdown {
  background-color: $gray100;
  box-shadow: 0 4px 4px 0 $transparent-gray;
  max-width: 100%;
  & li:not(:last-child) {
    border-bottom: 1px solid $gray400;
  }
}

.formControlRoot {
  background-color: $primary;
  border-radius: 0 8px 8px 0;
  min-width: 78px;
}

.icon {
  color: $white;
  transform: rotate(0);
}

.menuItemRoot {
  color: $black;
  height: 40px;
  padding: 0 16px;
  &.menuItemSelected {
    background-color: $primary;
    color: $white;
    &:hover {
      background-color: $primary;
    }
  }
}

.textFieldOutline {
  border-radius: 8px 0 0 8px;
}

.numberInput {
  border-radius: 8px 0 0 8px;
  height: 46px;
  width: 100%;
  &Container {
    flex-grow: 1;
  }
}

.label {
  transform: translate(14px, 16px) scale(1);
}
