@import 'styles/partials/_variables.module.scss';

.emptyStateSection {
  background: $white;
  width: 100%;
  & img {
    height: auto;
    margin-top: 40px;
    max-width: 466px;
  }
  & .message {
    color: $black;
    font-family: $font-nunito;
    font-size: 1.125rem;
    height: auto;
    letter-spacing: normal;
    line-height: 1.5;
    margin-top: 8px;
    text-align: center;
    width: 496px;
  }
  & .description {
    color: $black;
    font-size: 1.75rem;
    font-weight: normal;
    height: 32px;
    line-height: 1.33;
    margin-top: 24px;
    text-align: center;
  }
}
