@import './partials/_breakpoints.scss';
@import './partials/_normalize.scss';
@import './partials/_variables.module.scss';

// global style
* {
  box-sizing: border-box;
  font-family: $font-nunito;
}

body {
  background-color: $white;
}
