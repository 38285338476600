@import 'styles/partials/_variables.module.scss';

.circleIconButton {
  &Base {
    background-color: $primary;
    border-radius: 50%;
    box-shadow: 0 4px 8px 0 rgba(43, 100, 116, 0.22);
    color: $white;
    cursor: pointer;
    height: 48px;
    padding: 9.6px;
    width: 48px;

    &:hover {
      background-color: $primary;
    }
  }

  &Svg {
    & svg {
      fill: $white;
    }
  }
}
