@import 'styles/partials/variables.module';

.button {
  border-radius: 0;
  width: 100%;
  &:hover {
    background-color: $primary200;
  }
  &Grey {
    background-color: $gray200;
    border-radius: 0;
    &:hover {
      background-color: $primary200;
    }
  }
}

.container {
  flex-wrap: nowrap;
}

.icon {
  margin: auto 0;
  position: relative;
  top: 5px;
  &Dropdown {
    margin: auto 0 auto 15px;
    position: relative;
    top: 5px;
  }
  &Opacity {
    opacity: 0.5;
  }
}

.iconRead {
  color: $primary;
  left: 5px;
  position: relative;
  top: 5px;
  &:hover {
    color: $primary700;
  }
  &Dropdown {
    color: $primary;
    margin-right: 5px;
    position: relative;
    top: 10px;
    &:hover {
      color: $primary700;
    }
  }
}

.notificationDate {
  margin-left: 0;
  text-align: right;
}

.notificationInfo {
  flex-grow: 1;
  margin-left: 18px;
  max-width: 100%;
  text-align: left;
}

.opacity80 {
  opacity: 0.8;
}

.title {
  flex-grow: 1;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 85%;
}
