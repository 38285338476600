@font-face {
  font-family: 'Nunito Sans';
  src: local('Nunito Sans'), url('../fonts/nunito-sans-regular.woff') format('woff') /* Modern Browsers */,
    url('../fonts/nunito-sans-regular.ttf') format('truetype'); /* safari, Android, IOS */
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('../fonts/roboto-regular.woff') format('woff') /* Modern Browsers */,
    url('../fonts/roboto-regular.ttf') format('truetype'); /* safari, Android, IOS */
}
