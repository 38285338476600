@import 'styles/partials/_variables.module.scss';

.description {
  color: $black;
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.33;
  margin: 24px 0 0;
  text-align: center;
}

.message {
  color: $black;
  font-family: $font-nunito;
  font-size: 1rem;
  font-stretch: normal;
  font-style: normal;
  font-weight: normal;
  height: 24px;
  letter-spacing: normal;
  line-height: 1.5;
  margin: 16px 41px 0;
  text-align: center;
  width: 366px;
}

.emptyStateSection {
  background: $gray100;
  width: 100%;
}

.emptyStateSection img {
  height: auto;
  margin-top: 24px;
  max-width: 448px;
  width: 58%;
}
