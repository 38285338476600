@import 'styles/partials/variables.module';
@import 'styles/partials/breakpoints';

.buttonGroup {
  border-radius: 8px;
  box-shadow: 1px 0 4px 0 $transparent-gray;
  z-index: 0;
}

.buttonGroup > div {
  &:first-child button {
    border-radius: 8px 8px 0 0;
  }
}

.container {
  min-height: 670px;
}

.iconRead {
  color: $primary;
  left: 24px;
  position: relative;
  top: 10px;
  &:hover {
    color: $primary700;
    cursor: pointer;
  }
}

.primaryTitle {
  display: inline-block;
  font-weight: bold;
  margin-left: 20px;
}

.radio > span {
  font-size: 1rem;
}

.titleContainer {
  background-color: $white;
  margin: auto;
  padding: 0 45px;
  position: sticky;
  top: 88px;
  width: 75%;
  z-index: 1;
}
