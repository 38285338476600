@import 'styles/partials/variables.module';
@import 'styles/partials/breakpoints';

.appBar {
  height: 60px;
}
.toolbar {
  height: 60px;
  padding: 0 24px;
  .headerContainer {
    justify-content: space-between;
    .headerText {
      align-items: center;
      color: $white;
      display: flex;
      font-family: Roboto, serif;
      font-size: 1.125rem;
      font-weight: bold;
    }
    .loader {
      margin-right: 1rem;
    }
    .iconButton {
      padding: 0;
      .icon {
        color: $white;
        height: 36px;
        width: 36px;
        &Drop {
          color: $white;
        }
      }
      .profilePic {
        height: 36px;
        width: 36px;
      }
    }
  }
}

.myTeamMenu {
  & ul {
    padding: 0;
  }
  & li {
    padding: 0;
    &:not(:last-child) {
      border-bottom: 1px solid $gray400;
    }
  }
  .marginSession {
    margin-top: 4px;
  }
  .paper {
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 $transparent-gray;
  }
  .sessionMenu {
    & span {
      font-size: 1rem;
      font-weight: 600;
    }
    .logOut {
      background-color: $white;
      color: $gray800;
      padding: 8px 57px 8px 16px;
      &:hover {
        background-color: $primary200;
        color: $primary700;
      }
    }
    .changeRole {
      background-color: $white;
      color: $gray800;
      justify-content: flex-start;
      padding: 8px 16px;
      &:hover {
        background-color: $primary200;
        color: $primary700;
      }
    }
  }
}
