@import 'styles/partials/_variables.module.scss';

.addNewHeader {
  align-items: center;
  display: flex;
  gap: 16px;

  &Label {
    color: $primary700;
    font-family: $font-roboto;
    font-size: 2.25rem;
    &.centeredTitle {
      display: flex;
      justify-content: center;
      width: 100%;
    }
  }

  &__createButtonContainer {
    align-items: center;
    display: flex;
    gap: 16px;
  }
}
