@import 'styles/partials/variables.module.scss';

.paginationFooter {
  align-items: center;
  color: $black;
  display: flex;
  font-size: 14px;
  height: 81px;
  justify-content: center;
  line-height: 24px;

  & .containerDisplayedItems {
    align-items: center;
    display: flex;
    flex: 2;
    justify-content: center;
  }

  & .infoContainer {
    flex: 1;
    & svg {
      color: $primary;
      height: 48px;
      width: 48px;
    }
  }
}