@import 'styles/partials/variables.module.scss';

.searchBar {
  background-color: $primary200;
  border-radius: 8px;
  display: flex;

  & .barInput {
    flex: 1;
    & [class*='MuiInput-root'] {
      background-color: $primary200;
      border: 0;
      border-radius: 8px;
      color: $black;
      font-size: 0.875rem;
      height: 48px;
      line-height: 24px;
      padding: 12px 0 12px 16px;

      &::after,
      &::before {
        content: unset;
      }
      ::placeholder {
        color: $primary700;
        font-size: 1rem;
        opacity: 1;
      }

      & .icon {
        height: 2rem;
        width: 2rem;
      }

      & .searchIcon {
        color: $primary700;
      }

      & .clearIcon {
        color: $primary;
        cursor: pointer;
        margin-right: 24px;
      }
    }
  }

  & .button {
    background-color: $white;
    border: 1px solid $primary;
    color: $primary;
    font-weight: bold;
  }
}