@import 'styles/partials/variables.module';
@import 'styles/partials/breakpoints';

.bold {
  font-weight: bold;
}

.topBar {
  background-color: $primary700;
  color: $white;
  height: 60px;
  .iconButtonContainer {
    align-items: center;
    display: flex;
    height: 60px;
    padding-left: 24px;
    .iconButton {
      color: $white;
      height: fit-content;
      padding: 0;
      .arrowBack {
        margin: 0 8px 0 0;
      }
      .typographyInline {
        font-size: 1rem;
      }
    }
  }
  .actionButtonContainer,
  .roleLabelContainer{
    padding-right: 24px;
  }
}
