@import 'styles/partials/variables.module.scss';

.date {
  margin: 0;
  padding: 0;
  button {
    color: $gray800;
    transform: translateX(12px);
  }
  > div {
    max-height: 48px;
  }
  label {
    transform: translate(14px, 16px) scale(1);
  }
}

.dateText {
  display: flex;
  justify-content: flex-start;
}
