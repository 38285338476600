@import 'styles/partials/_variables.module.scss';

.projectDashboard {
  &Heading {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &Layout {
      display: flex;
      flex-direction: column;

      &__addNewContainer {
        padding: 75px 0;
      }
      &__addNewContainerEmpty {
        padding-top: 75px;
      }
    }
  }

  &Table {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    position: relative;
  }
  &NoPagination {
    margin-bottom: 81px;
  }
}
