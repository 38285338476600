@import 'styles/partials/_variables.module.scss';

.appraising,
.approved,
.draft,
.pending,
.rejected {
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 0.8rem;
  font-weight: bold;
  height: 29px;
  justify-content: center;
  width: 86px;
}

.appraising {
  background-color: $appraising;
  color: $appraising-text;
}

.approved {
  background-color: $approved;
  color: $approved-text;
}

.draft {
  background-color: $white;
  border: 1px solid $gray400;
  color: $gray800;
}

.pending {
  background-color: $on-hold;
  color: $on-hold-text;
}

.rejected {
  background-color: rgba($red100, 0.15);
  color: $red200;
}
