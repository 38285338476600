@import 'styles/partials/variables.module.scss';

.formTitle {
  margin: 44px 0 18px;
}

.formField {
  margin: 18px 0;
  &CalendarSpacing {
    width: 24px;
  }
}

.commentsCount {
  color: $gray800;
}
