@import 'styles/partials/_variables.module.scss';

.main {
  align-items: center;
  background-image: url('../../../../assets/images/Background_1.png');
  background-repeat: no-repeat;
  background-size: contain;
  height: 100vh;
}

.actionArea {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  width: 34%;
  .errorMessage{
    color: $red-field-error;
    font-size: 0.75rem;
    margin-top: 8px;
  }
}

.logIn{
  align-self: center;
  height: 36px;
  margin-top: 20px;
}

.textField {
  &Email {
    & label {
      transform: translate(14px, 16px) scale(1);
    }
  }
  &Password {
    margin-top: 20px;
    & label {
      transform: translate(14px, 16px) scale(1);
    }
  }
}