@import 'styles/partials/variables.module.scss';

.avatar {
  background-color: $primary700;
  color: $white;
  font-size: 0.75rem;
  height: 36px;
  width: 36px;
}

.teamRosterRowCell {
  color: $black;
  cursor: default;
  height: 56px;
  max-width: 0;
  overflow: hidden;
  padding: 0 16px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selectAutocomplete {
  width: 316px;
}

.selectCustomFont {
  font-size: 0.875rem;
}

.moreIcon {
  cursor: pointer;
  padding: 0;
}

.view {
  color: $color-link;
  cursor: pointer;
}
