@import 'styles/partials/variables.module.scss';

.projectDetailsContainer {
  padding: 12px 196px 0;
  .formTitle {
    margin: 44px 0 12px;
  }
  .formField {
    margin: 12px 0;
    &CalendarSpacing {
      width: 24px;
    }
    .commentsCount {
      color: $gray800;
      margin-top: 8px;
    }
  }
}

.projectDetailsModalContainer {
  margin: -14px 0 -6px;
  padding: 0;
  .formTitle {
    margin: 38px 0 18px;
  }
  .formField {
    margin: 18px 0;
    &CalendarSpacing {
      width: 24px;
    }
    .commentsCount {
      color: $gray800;
      margin-top: 8px;
    }
  }
}
