@import 'styles/partials/_variables.module.scss';

.iconTooltip {
  height: 20px;
  margin-left: 10px;
  width: 20px;
}

.title {
  font-family: $font-roboto;
  font-size: 1.25rem;
  font-weight: normal;
  letter-spacing: 0.36px;
  line-height: normal;
}

.bold {
  font-weight: bold;
}

.tooltip {
  margin: 0 0 4px;
  max-width: 364px;
}
