@import 'styles/partials/variables.module.scss';

.teamRosterAppraisal {
  &_Section {
    display: flex;
    flex-direction: column;
    margin-bottom: 56px;

    &Title {
      font-family: $font-roboto;
      font-size: 1.25rem;
    }
  }

  &TitleContainer {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  & .showMoreButton {
    align-self: flex-start;
    color: $color-link;
    padding: 6px 0;
    & span {
      font-size: 0.875rem;
      font-weight: normal;
      line-height: 24px;
    }
    &:hover {
      background-color: $white;
    }
  }

  &PositionCardsContainer {
    column-gap: 32px;
    row-gap: 16px;
    width: 100%;
  }
}
