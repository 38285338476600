@import 'styles/partials/variables.module.scss';

.link,
.text{
  color: $primary700;
  font-size: 0.875rem;
  text-decoration: none;
}

.text,
.separator{
  font-weight: bold;
}

.separator{
  margin: 0 4px;
}