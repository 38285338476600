.tableSkeleton {
  padding: 24px;

  & .paginationHeaderSkeleton,
  & .paginationFooterSkeleton {
    display: flex;
    justify-content: flex-end;
  }

  & .paginationHeaderSkeleton {
    margin: 16px 0;
  }
}
