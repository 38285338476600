@import 'styles/partials/_variables.module.scss';
@import 'styles/partials/_breakpoints.scss';

.closeButton {
  color: $primary;
  height: 1.875rem;
  position: absolute;
  right: 12px;
  top: 12px;
  width: 1.875rem;
}

.closeButton svg {
  height: 1.875rem;
  width: 1.875rem;
}

.dataDocument {
  width: 33%;
}

.documentContainer {
  max-height: 120vh;
  overflow: auto;
  width: 100%;
}

.documentsPagination {
  width: 33%;
}

.emptyState {
  background-color: $gray200;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  height: 50vh;
  justify-content: center;
}

.emptyStateText {
  color: $gray600;
  margin-top: 40px;
  padding-bottom: 24px;
  padding-top: 25px;
  text-align: center;
}

.failedStateTextSub {
  color: $gray600;
  font-size: 1.125rem;
  text-align: center;
}

.failedStateText {
  color: $gray600;
  text-align: center;
}

.fileDate {
  color: $gray700;
  font-size: 1rem;
  line-height: 1.5;
  text-align: left;
}

.filename {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.03rem;
  text-align: left;
  word-break: break-word;
}

.iconButtonLeft {
  background-color: $primary;
  border-radius: 0;
  color: white;
  height: 3.5rem;
  left: calc(4.5rem - 3.5rem / 2 - 0.625rem);
  position: fixed;
  top: 50vh;
  width: 3.5rem;
}

.iconButtonRight {
  background-color: $primary;
  border-radius: 0;
  color: white;
  height: 3.5rem;
  position: fixed;
  right: calc(4.5rem - 3.5rem / 2 + 0.625rem);
  top: 50%;
  width: 3.5rem;
}

.imagesContainer {
  height: auto;
  width: 100%;
}

.informationContainer {
  margin-bottom: 33px;
  margin-left: 24px;
  width: 100%;
}

.pagination {
  color: $gray700;
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
  text-align: center;
}

.previewDocumentContainer {
  background-color: $gray200;
  min-height: 100vh;
  padding: 48px 30px 80px;
  width: 80vw;
}

@media screen and (max-width: $mobile) {
  .dataDocument {
    width: 75%;
  }

  .documentsPagination {
    width: 25%;
  }

  .fileDate {
    font-size: 1rem;
  }

  .filename {
    font-size: 1rem;
  }

  .pagination {
    font-size: 1rem;
  }

  .previewDocumentContainer {
    min-height: 100vh;
    width: 100%;
  }

  .iconButtonLeft {
    left: 0;
  }

  .iconButtonRight {
    right: 0;
  }
}
