@import 'styles/partials/variables.module.scss';

.attachBox {
  border: solid 1px $primary;
  border-radius: 8px;
  max-height: 48px;
  width: 100%;
}

.attachText {
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.attachBoxDisabled {
  border: solid 1px $gray600;
  border-radius: 8px;
  max-height: 48px;
  padding: 10px 12px 12px 16px;
  width: 100%;
}

.attachTextDisabled {
  color: $gray600;
  font-size: 1rem;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.fillDisabled path {
  fill: $gray600;
}

.iconClear {
  margin-left: auto;
  padding: 0;
}

.inputFile {
  display: none;
}

.label {
  cursor: pointer;
  display: inline-block;
  margin: auto 0 0 auto;
  padding: 10px 12px 12px 16px;
  width: 100%;
}

.uploadedBox {
  background-color: $primary200;
  border: none;
}

.uploadedFileBox {
  padding: 10px 12px 12px 16px;
}

.uploadIcon {
  padding: 0;
}

.uploadedText {
  text-decoration: underline;
}
