@import 'styles/partials/variables.module.scss';

.chipRoot {
  background-color: $white;
  border: 1px solid $black;
  border-radius: 8px;
  color: $black;
  font-size: 0.875rem;
  justify-content: center;
  margin: 8px 8px 0 0;
  &:focus {
    background-color: $white;
  }
  &:hover {
    background-color: $white;
  }
}

.activeChip,
.activeChip:focus {
  background-color: $primary200;
  border: 1px solid $primary200;
  color: $primary700;
  &:hover {
    background-color: $primary200;
  }
}

.disabledChip,
.disabledChip:focus {
  background-color: $white;
  border: 1px solid $gray400;
  color: $gray700;
  pointer-events: none;
  &:hover {
    background-color: $white;
  }
}

.activeChip.disabledChip {
  background-color: $gray-disabled-button;
  border: 1px solid $gray-disabled-button;
  color: $gray700;
  pointer-events: none;
}

.helperText {
  font-size: 0.75rem;
}

.title {
  color: $gray800;
  font-size: 0.75rem;
  margin: 8px 0 0;
}

.error {
  color: $red-field-error;
  margin: 8px 0;
}
