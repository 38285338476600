@import 'styles/partials/_variables.module.scss';

.projectRowCell {
  color: $black;
  cursor: default;
  max-width: 0;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;

  &Slim {
    padding: 12px 0 12px 16px !important;
  }

  &Plump {
    padding: 16px 0 16px 16px !important;
  }
}

.link {
  color: $color-link;
  text-decoration: none;
}

.moreIcon {
  cursor: pointer;
  padding: 0;
  & svg {
    fill: $black;
  }
}
