@import 'styles/partials/_variables.module.scss';

.roleContainer,
.specsContainer {
  column-gap: 20px;

  & .subtitle {
    font-family: $font-roboto;
    font-size: 1.25rem;
    font-weight: normal;
  }

  & .description {
    color: $gray800;
    font-size: 0.75rem;
    margin-bottom: 4px;
  }

  & .value {
    color: $black;
    font-size: 1rem;
  }
}

.roleContainer {
  margin-top: 12px;
}

.specsContainer {
  margin-top: 32px;
}