@import 'styles/partials/_variables.module.scss';

.container {
  background-color: $primary100;
  min-height: 100%;
  & .generalContainer {
    flex-direction: column;

    & .stepInfo {
      margin-top: 16px;
      padding: 16px 122px;
    }

    & .stepContainer {
      padding: 16px 122px 64px;

      & .cardContent {
        padding: 0;
      }

      & .cardActions {
        justify-content: flex-end;
        margin: 52px 196px 56px 0;
        padding: 0;

        & .button {
          height: 40px;
          margin-left: 24px;
        }

        &__Table {
          margin: 8px 98px 56px 0;
        }
      }
    }
  }
}
