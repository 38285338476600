@import 'styles/partials/_variables.module.scss';

.dashboardHelpMenu {
  bottom: 12px;
  position: fixed;
  right: 12px;

  &HelpIcon {
    height: 40px;
    margin: 12px;
    width: 40px;
    & i {
      font-stretch: normal;
      font-style: normal;
      font-weight: 900;
      line-height: 0.9;
      padding: 9.2px 15.8px;
      text-align: center;
    }
  }

  &Paper{
    box-shadow: 0 4px 16px 0 rgba(25, 57, 66, 0.25);
  }

  &Content {
    border: solid 1px rgba(196, 196, 196, 0.6);
    border-radius: 8px;
    padding: 32px 32px 33px;
    width: 564px;

    &Button{
      padding: 0;
    }

    &Heading {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 40px;
    }

    &Steps {
      display: flex;
      flex-direction: column;
      &_Step {
        &__heading,
        &__content {
          display: flex;
        }

        &__heading {
          align-items: center;
          & > *:first-child {
            margin-right: 16px;
          }
        }

        &__content {
          justify-content: center;

          & p {
            flex: 1;
            font-size: 0.75rem;
            line-height: 16px;
            padding: 0;
            width: 464px;
            & i {
              font-weight: 600;
            }
          }

          &.draftContent,
          &.onHoldContent {
            & > *:first-child {
              margin-right: 24px;
            }
          }

          &.draftContent {
            height: 61px;
            & > p {
              margin: 8px 0;
            }
          }

          &.onHoldContent {
            height: 76px;
            & > p {
              margin: 8px 0;
            }
          }

          &.approvedContent,
          &.rejectedContent {
            margin-left: 32px;
            & > p {
              margin: 8px 0 16px;
            }
          }
        }
      }
    }
  }
}