@import 'styles/partials/variables.module.scss';

.dialog > div > [class*='MuiPaper-root'] {
  align-items: flex-end;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  max-height: 95vh;
  padding: 56px;

  & .dialogTitle {
    align-self: baseline;
    font-family: Roboto, serif;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 24px;
  }

  & .dialogContent {
    overflow-y: initial;
    padding: 0;
  }

  & .dialogActions {
    display: flex;
    justify-content: flex-end;
    margin-top: 52px;
    width: 100%;

    & .dialogButton {
      margin-left: 24px;
      padding: 8px 16px;
      
      &.cautionButton {
        background-color: $red200;
        color: $white;
        padding: 8px 16px;
      }
    }
  }
}

.paper {
  max-width: 676px;
  &Large {
    max-width: 760px;
  }
}

.paper::-webkit-scrollbar {
  width: 13px;
  &-button {
    display: none;
  }
  &-thumb {
    background-color: $gray700;
    border-left: 4px $white solid;
    border-radius: 6px;
    border-right: 4px $white solid;
  }
  &-track {
    margin: 8px 0;
  }
}
