@import 'styles/partials/_variables.module.scss';

.overviewCard {
  background-color: $gray200;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  min-width: 232px;
  padding: 16px;

  &:hover {
    background-color: $primary200;
  }

  &__title,
  &__hours {
    cursor: default;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__title {
    color: $primary700;
    font-size: 0.875rem;
    margin-bottom: 4px;
  }

  &__hours {
    color: $primary700;
    font-family: $font-roboto;
    font-size: 1.125rem;
    font-weight: bold;
  }
}
