@import 'partials/variables.module';

.snackbarRoot {
  & div:first-child {
    align-items: flex-start;
    background-color: $gray900;
    border-radius: 8px;
    box-shadow: 0 4px 4px 0 rgba(50, 93, 127, 0.2);
    flex-wrap: nowrap;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.5;
    max-width: 662px;
    padding: 12px 16px;
    width: 662px;

    & div:first-child {
      align-items: flex-start;
      padding: 0;

      & svg {
        margin-right: 8px;
      }
    }
  }
}
