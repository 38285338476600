input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.label {
  transform: translate(14px, 16px) scale(1);
}

.multilineInput {
  min-height: 139px;
}
