@import 'styles/partials/_variables.module.scss';
@import 'styles/partials/_breakpoints.scss';

.button {
  font-size: 1.125rem;
  height: 40px;
  margin-bottom: 16px;
  padding: 16px;
  &NewOrDuplicate {
    margin-right: 24px;
  }
}

.buttonSave {
  font-size: 1.125rem;
  height: 40px;
  padding: 16px;
  &Hide {
    visibility: hidden;
  }
}

.addNewPositionTitle {
  margin-bottom: 20px;
  margin-top: 20px;
}

.title {
  margin-bottom: 40px;
}

.subtitle {
  font-size: 1.25rem;
  font-weight: normal;
  margin-bottom: 24px;
}

.addPosition {
  height: 46px;
}

.ksquarianContainer {
  margin-bottom: 14px;
}

.roleContainer {
  margin-bottom: 56px;
  &Cocreator {
    margin-bottom: 24px;
  }
}

.specsContainer {
  margin-bottom: 16px;
  row-gap: 24px;
}

.modalContainer {
  width: 760px;
}

.numberOfPositions {
  font-size: 1.25rem;
  font-weight: normal;
}

.active {
  background-color: $primary !important;
  color: $white !important;
}

.filled {
  background-color: $gray400;
}

.icon {
  margin-right: 36px;
}
