@import 'styles/partials/_variables.module.scss';

.chip {
  background-color: $primary;
  color: $white;
  margin: 0px 16px 8px 0;
}

.chip svg {
  color: $white;
}

.chip svg:hover {
  color: $white;
}

.chip:focus {
  background-color: $primary;
}

.containedAutocomplete {
  background-color: $white;
  border-radius: 8px;
  margin: 0 0 8px;
}

.icon {
  color: $gray800;
}

.input {
  height: 46px;
}

.noOption {
  display: flex;
  font-size: 1rem;
  width: 100%;
}

.offset {
  transform: translate(14px, 20px) scale(1);
}

.offsetMargin {
  margin-left: 20px;
  transform: translate(0, 16px) scale(1);
}
