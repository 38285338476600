@import 'styles/partials/variables.module.scss';

.rejectionDetails {
  padding: 0 !important;

  & .rejectionDetailsContent {
    font-size: 0.875rem;
    letter-spacing: normal;
    max-width: 800px;
    padding-bottom: 31px;
    padding-left: 54px;

    & .rejectionDetailsTitle {
      color: $red200;
    }
  }
}