@import 'styles/partials/variables.module.scss';

.formContainer {
  height: fit-content;
  margin: 8px 0 12px;
  min-height: 100px;
  width: 552px;
  .approveError {
    color: $red-field-error;
    font-size: 0.75rem;
    margin-top: 8px;
  }
  .iconButtonContainer {
    margin: 16px 32px 0 0;
    .button {
      background-color: $primary200;
      height: 40px;
      width: 40px;
      .icon {
        height: 20px;
        width: 20px;
      }
      &Selected {
        background-color: $primary;
      }
    }
    .label {
      font-size: 0.75rem;
      font-weight: bold;
      margin: 4px 0 0;
    }
  }

  .inputContainer {
    margin-top: 28px;
    .helperText {
      color: black;
      margin: 8px 0 0;
    }
    .multilineInput {
      min-height: 120px;
    }
  }
}
