@import 'styles/partials/variables.module.scss';

.textfield {
  height: 40px;
  margin-right: 8px;
  width: 144px;
  & .input {
    height: 40px;
    padding-right: 8px;
    & .adornment {
      padding-right: 0px;
    }
  }
  & .tooltip {
    cursor: pointer;
  }
}

.icon {
  margin-top: 2px;
  padding: 8px;
  &Confirm {
    color: $approved-text;
    stroke: $approved-text;
    stroke-width: 1px;
    &Disabled {
      color: $gray600;
      stroke: $gray600;
      stroke-width: 1px;
    }
  }
  &Cancel {
    color: $red-field-error;
    stroke: $red-field-error;
    stroke-width: 1px;
  }
  &Edit {
    margin-bottom: 4px;
    padding: 0;
  }
  &Error {
    color: $red-field-error;
  }
  & svg {
    height: 18px;
    width: 18px;
  }
}

.popper {
  margin-right: -10px;
}

.value {
  margin: auto 0;
}
